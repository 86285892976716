// extracted by mini-css-extract-plugin
export var adminPortal = "solutions-module--adminPortal--519fc";
export var appFlex = "solutions-module--appFlex--a117b";
export var appInline = "solutions-module--appInline--8c8a1";
export var appWeb = "solutions-module--appWeb--d38cc";
export var assessorPortal = "solutions-module--assessorPortal--91b33";
export var dashLine = "solutions-module--dashLine--49c1e";
export var employerPortal = "solutions-module--employerPortal--a7046";
export var flex = "solutions-module--flex--399ad";
export var flowChart = "solutions-module--flowChart--0e794";
export var item = "solutions-module--item--47c99";
export var quotMark = "solutions-module--quotMark--96eea";
export var solutions = "solutions-module--solutions--12514";
export var studPortal = "solutions-module--studPortal--48339";
export var webFlex = "solutions-module--webFlex--b4950";
export var webInline = "solutions-module--webInline--6d207";
export var widthControl = "solutions-module--widthControl--2646d";