import React from "react";
import Layout from "../components/layout";
import * as styles from "./solutions.module.css";
import * as styleMain from "../styles/main.module.css";
import bannerPhoto4 from "../images/bannerPhoto4.png";
import bigArrowIcon from "../images/big_arrow_icon.png";
import bigStudentIcon from "../images/bigStudentIcon.png";
import bigTeacherIcon from "../images/bigTeacherIcon.png";
import bigSchoolIcon from "../images/bigSchoolIcon.png";
import bigEmployerIcon from "../images/bigEmployerIcon.png";
import soluDashboardPhoto from "../images/soluDashboardPhoto.png";
import courseDesignPhoto from "../images/courseDesignPhoto.png";
import soluEnrolmentPhoto from "../images/soluEnrolmentPhoto.png";
import traineeshipManagementPhoto from "../images/traineeshipManagementPhoto.png";
import trainersAssessorsPhoto from "../images/trainersAssessorsPhoto.png";
import blueEnrolmentIcon from "../images/blueEnrolmentIcon.png";
import blueAssessIcon from "../images/blueAssessIcon.png";
import blueCertificateIcon from "../images/blueCertificateIcon.png";
import blueNotificationIcon from "../images/blueNotificationIcon.png";
import quotationBackIcon from "../images/quotation_back_icon.png";
import quotationFrontIcon from "../images/quotation_front_icon.png";
import dashLine from "../images/dash_separation_line.png";

const Solutions = () => {
  return (
    <Layout>
      <main className={styles.solutions}>
        {/* ====== 0 banner ====== */}
        <section className={styleMain.banner}>
          <h2>Help towards a business success</h2>
          <h5>
            We focus on your business, not the computers, providing
            cost-effective systems
            <br />
            that save time, effort and money by meeting real business needs.
          </h5>
          <button className={styleMain.bannerBtn}>FIND OUT MORE</button>
          <img
            className={styleMain.bannerImg}
            src={bannerPhoto4}
            alt="banner_photo_4"
          />
        </section>
        {/* ====== 1 flowChart ====== */}
        <section className={styles.flowChart}>
          <h3>IVEMS</h3>
          <div className={styles.flex}>
            <div className={styles.item}>
              <img src={bigStudentIcon} alt="bigStudentIcon" />
              <h4>Students</h4>
            </div>
            <img src={bigArrowIcon} alt="big_arrow_icon" />
            <div className={styles.item}>
              <img src={bigTeacherIcon} alt="bigTeacherIcon" />
              <h4>Teacher</h4>
            </div>
            <img src={bigArrowIcon} alt="big_arrow_icon" />
            <div className={styles.item}>
              <img src={bigSchoolIcon} alt="bigSchoolIcon" />
              <h4>School Admin</h4>
            </div>
            <img src={bigArrowIcon} alt="big_arrow_icon" />
            <div className={styles.item}>
              <img src={bigEmployerIcon} alt="bigEmployerIcon" />
              <h4>Employers</h4>
            </div>
          </div>
        </section>
        {/* ====== 2 adminPortal ====== */}
        <section className={styles.adminPortal}>
          <h3>Admin Portal</h3>
          <div className={styles.flex}>
            <article>
              <img src={soluDashboardPhoto} alt="soluDashboardPhoto" />
              <h4>Dashboard</h4>
              <p>
                {/* A dynamic dashboard to improve your productivity real-time
                reporting, track your trainers' and learners' progress and
                activity, review performance, and gain immediate insight. */}
              </p>
            </article>
            <article>
              <img src={courseDesignPhoto} alt="courseDesignPhoto" />
              <h4>Course Design</h4>
              <span>
                <p>Create and setup course, unit, quizzes</p>
                <p>
                  Question mapping - map the assessment to the unit of
                  competency and difficult level
                </p>
                <p>
                  Computer marked question types including multi-choice,
                  true/false, fill in the blanks, click in order
                </p>
                <p>
                  Trainer marked question types include short answer, upload
                  assessment
                </p>
              </span>
            </article>
            <article>
              <img src={soluEnrolmentPhoto} alt="soluEnrolmentPhoto" />
              <h4>Enrolment</h4>
              <p>
                We start serving students from their profile creation, email
                validation, course enrolment study/visiting plan.
              </p>
            </article>
            <article>
              <img
                src={traineeshipManagementPhoto}
                alt="traineeshipManagementPhoto"
              />
              <h4>Traineeship Management</h4>
              <p>
                We maintain, control and monitor the whole journey of
                traineeship from trainer assigning, creation of visiting plan,
                trainer's visiting, conducting the training, feedback and
                result, and to course completion.
              </p>
            </article>
            <article>
              <img src={trainersAssessorsPhoto} alt="trainersAssessorsPhoto" />
              <h4>Trainers and Assessors</h4>
              <p>
                Allows trainers to stay compliant by recording their competency
                and currency.
              </p>
              <span>
                <p>Record vocational and teaching competencies</p>
                <p>Record industry accreditations and licences</p>
                <p>Track work history</p>
                <p>Record vocational and teaching currency activities</p>
                <p>Supervision agreements and individual plans</p>
              </span>
            </article>
          </div>
        </section>
        {/* ====== 3 studPortal ====== */}
        <section className={styles.studPortal}>
          <h3>Students Portal</h3>
          <p>Interactive one stop eLearning System.</p>
          <p>
            Study anywhere, anytime and on your desktop and tablets. Learners
            can complete assessments and track results easily with our intuitive
            navigation system. Our student console tracks:
          </p>
          <div className={styles.flex}>
            <article>
              <img src={blueEnrolmentIcon} alt="blueEnrolmentIcon" />
              <h4>Enrolments</h4>
              <p>
                {/* A dynamic dashboard to improve your productivity Real-time
                reporting, track your trainers' and learners' progress and
                activity, review performance, and gain immediate insight. */}
              </p>
            </article>
            <article>
              <img src={blueAssessIcon} alt="blueAssessIcon" />
              <h4>Online assessments and workshops</h4>
              <p>
                {/* A dynamic dashboard to improve your productivity Real-time
                reporting, track your trainers' and learners' progress and
                activity, review performance, and gain immediate insight. */}
              </p>
            </article>
            <article>
              <img src={blueCertificateIcon} alt="blueCertificateIcon" />
              <h4>Badges or certificates </h4>
              <p>
                We start serving students from their profile creation, email
                validation, course enrolment and study/visiting plan.
              </p>
            </article>
            <article>
              <img src={blueNotificationIcon} alt="blueNotificationIcon" />
              <h4>Notifications</h4>
              <p>
                {/* A dynamic dashboard to improve your productivity Real-time
                reporting, track your trainers' and learners' progress and
                activity, review performance, and gain immediate insight. */}
              </p>
            </article>
          </div>
        </section>
        {/* ====== 4 assessorPortal ====== */}
        <section className={styles.assessorPortal}>
          <h3>Assessors Portal</h3>
          <p>A flexible marking system to save your time.</p>
          <p>
            The user friendly interface will make it easier to track a students
            progress. Receive notifications when learners have completed an
            assessment and manage assessments by due date or course, to ensure
            you are always on top of your learners' progress.
          </p>
          <p>
            Avoid the need for paper clutter thanks to the innovative electronic
            sign-in and ability to amrk questions or close off workshops with a
            click of a button.
          </p>
        </section>
        {/* ====== 5 appWeb ====== */}
        <section className={styles.appWeb}>
          <div className={styles.appInline}>
            <span>
              APP
              <img
                className={styles.quotMark}
                src={quotationBackIcon}
                alt="icon"
              />
              <img className={styles.dashLine} src={dashLine} alt="icon" />
            </span>
          </div>
          <div className={styles.appFlex}>
            <article>
              <h4>Sign in / out</h4>
              {/* <p>
                This is a detailed content.This is a detailed content. This is a
                detailed content. This is a detailed content.
              </p> */}
            </article>
            <article>
              <h4>Job list</h4>
              {/* <p>
                This is a detailed content.This is a detailed content. This is a
                detailed content. This is a detailed content.
              </p> */}
            </article>
            <article>
              <h4>Student list and detail</h4>
              {/* <p>
                This is a detailed content.This is a detailed content. This is a
                detailed content. This is a detailed content.
              </p> */}
            </article>
            <article>
              <h4>Visit plan creation/modification</h4>
              {/* <p>
                This is a detailed content.This is a detailed content. This is a
                detailed content. This is a detailed content.
              </p> */}
            </article>
            <article>
              <h4>Training material</h4>
              {/* <p>
                This is a detailed content.This is a detailed content. This is a
                detailed content. This is a detailed content.
              </p> */}
            </article>
            <article>
              <h4>Student Contact Report and other documents upload</h4>
              {/* <p>
                This is a detailed content.This is a detailed content. This is a
                detailed content. This is a detailed content.
              </p> */}
            </article>
            <article>
              <h4>Instant notification/message</h4>
              {/* <p>
                This is a detailed content.This is a detailed content. This is a
                detailed content. This is a detailed content.
              </p> */}
            </article>
          </div>
          <div className={styles.webInline}>
            <span>
              WEB
              <img
                className={styles.quotMark}
                src={quotationFrontIcon}
                alt="icon"
              />
              <img className={styles.dashLine} src={dashLine} alt="icon" />
            </span>
          </div>
          <div className={styles.webFlex}>
            <article>
              <h4>Student list and progress</h4>
              {/* <p>
                This is a detailed content.This is a detailed content. This is a
                detailed content. This is a detailed content.
              </p> */}
            </article>
            <article>
              <h4>Visit plan</h4>
              {/* <p>
                This is a detailed content.This is a detailed content. This is a
                detailed content. This is a detailed content.
              </p> */}
            </article>
            <article>
              <h4>Working hours summary and reports</h4>
              {/* <p>
                This is a detailed content.This is a detailed content. This is a
                detailed content. This is a detailed content.
              </p> */}
            </article>
          </div>
        </section>
        {/* ====== 6 employerPortal ====== */}
        <section className={styles.employerPortal}>
          <div className={styles.widthControl}>
            <aside>
              <h3>Employers Portal</h3>
              <p>
                Tools designed for employers to monitor the training of their
                staff/apprentices.
              </p>
            </aside>
            <div className={styles.flex}>
              <article>
                <h4>Track learners' progress</h4>
                {/* <p>
                  This is a detailed content. This is a detailed content. This
                  is a detailed content.This is a detailed content.
                </p> */}
              </article>
              <article>
                <h4>Access visit plan</h4>
                {/* <p>
                  This is a detailed content. This is a detailed content. This
                  is a detailed content.This is a detailed content.
                </p> */}
              </article>
              <article>
                <h4>Receive/send modification of visit plan</h4>
                {/* <p>
                  This is a detailed content. This is a detailed content. This
                  is a detailed content.This is a detailed content.
                </p> */}
              </article>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Solutions;

export const Head = () => <title>Auokka | Solutions</title>;
